@use "mixin";


$bg-color: #ef5350;
$black: #222222;
$sidebar-color: #263238;


header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 9999;

    .header-inner {
        display: flex;
        justify-content: space-between;
        padding: 1em;

        @include mixin.mq(sp) {
            padding: .6em;
        }

        .header-inner-left {

            .header-inner-left-link {
                display: inline-block;
                width: 25%;
                height: auto;

                @include mixin.mq(bg-tab) {
                    width: 35%;
                }

                @include mixin.mq(sm-tab) {
                    width: 40%;
                }

                @include mixin.mq(sp) {
                    width: 55%;
                }

                .header-inner-left-logo {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .header-inner-right {

            .menu-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                align-content: center;
                height: 100%;
                width: 70%;
                position: relative;

                @include mixin.mq(sp) {
                    width: 100%;
                }

                .menu-circle {
                    width: 75px;
                    height: 75px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    cursor: pointer;
                    transition: all .3s;
                    border: 4px solid #fff;

                    @include mixin.mq(sp) {
                        width: 40px;
                        height: 40px;
                        border: 3px solid #fff;
                    }

                    .line-wrapper {
                        width: 50px;
                        height: 26px;
                        display: flex;
                        justify-content: space-around;
                        flex-direction: column;
                        align-items: center;

                        @include mixin.mq(sp) {
                            height: 18px;
                        }

                        .line {
                            height: 3px;
                            width: 30px;
                            background: #fff;
                            display: block;
                            border-radius: 50px;
                            transition: all .3s;

                            @include mixin.mq(sp) {
                                height: 2px;
                                width: 18px;
                            }

                            &:nth-child(1) {

                                &.borderactive {
                                    transform: rotate(45deg) translateY(7px) translateX(8px);

                                    @include mixin.mq(sp) {
                                        transform: rotate(45deg) translateY(5px) translateX(5px);       
                                    }
                                }
                                
                            }

                            &:nth-child(3) {

                                &.borderactive {
                                    transform: rotate(-45deg) translateY(-5px) translateX(6px);

                                    @include mixin.mq(sp) {
                                        transform: rotate(-45deg) translateY(-3px) translateX(4px);       
                                    }
                                }
                                
                            }

                            &:nth-child(2) {

                                &.borderactive {
                                    transform: translateX(-100%);
                                    opacity: 0;
                                }
                                
                            }
                        }
                    }
                }
            }
        }
    }
}


.menu-circle:before,
.menu-circle:after {
  content: "";
  display: block;
  height: 150px;
  width: 150px;
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  transition: transform .5s, opacity 1s;
  top: calc(50% - 75px);
  left: calc(50% - 75px);
}

.menu-circle:before {
  background: lighten($bg-color, 25);
  z-index: -1;
}

.menu-circle:after {
  background: lighten($bg-color, 20);
  z-index: -2;
}

.menu-circle.clicked:before {
  transform: scale(1);
  opacity: 0;
}

.menu-circle.clicked:after {
  transform: scale(1.75);
  opacity: 0;
}

#g-nav {

    &.panelactive{
        /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
        position:fixed;
        z-index: 999;
        top: 0;
        width:100%;
        height: 100vh;

        #g-nav-list{
            display: block; /*クラスが付与されたら出現*/

            ul {
                opacity:1;
    
                /* 背景が出現後にナビゲーション li を表示※レイアウトによって調整してください。不必要なら削除*/
                li{
                    animation-name:gnaviAnime;
                    animation-duration:1s;
                    animation-delay:.2s;/*0.2 秒遅らせて出現*/
                    animation-fill-mode:forwards;
                    opacity:0;
                }
                    @keyframes gnaviAnime{
                    0% {
                    opacity: 0;
                    }
                    100% {
                    opacity: 1;
                    }
                }
            }
       }
    }

    /*ナビゲーションの縦スクロール*/
    #g-nav-list{
        display: none;/*はじめは表示なし*/
        /*ナビの数が増えた場合縦スクロール*/
        position: fixed;
        z-index: 999; 
        width: 100%;
        height: 100vh;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        /*ナビゲーション*/
        ul {
            opacity: 0;/*はじめは透過0*/
            /*ナビゲーション天地中央揃え※レイアウトによって調整してください。不必要なら削除*/
            position: absolute;
            z-index: 999;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);

            /*リストのレイアウト設定*/
            li {
                text-align: center; 
                list-style: none;

                a {
                    @include mixin.mainFont;
                    font-size: 2em;
                    color: rgba(255,255,255,1);
                    text-decoration: none;
                    padding: 15px;
                    display: block;
                    text-transform: uppercase;
                    letter-spacing: 0.2em;
                    transition: all .5s;

                    &:hover {
                        color: rgba(255,255,255,.6);
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.5em;
                    }
                }
            }
        }
    }
}


/*丸の拡大*/
.circle-bg{
    position: fixed;
    z-index:3;
    /*丸の形*/
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: linear-gradient(-225deg, #2CD8D5 0%, #C5C1FF 56%, #FFBAC3 100%);
    /*丸のスタート位置と形状*/
    transform: scale(0);/*scaleをはじめは0に*/
    top:calc(50% - 50px);/*50%から円の半径を引いた値*/
    left:calc(50% - 50px);/*50%から円の半径を引いた値*/
    transition: all .6s;/*0.6秒かけてアニメーション*/

    &.circleactive{
        transform: scale(50);/*クラスが付与されたらscaleを拡大*/
      }
}