$subHeadColor: #acb5fa;
$designColor: #acb5fa;
$programColor: #d1bae5;
$wmColor: #efbfd4;
$mvColor: #f4d7cf;
$adColor: #ffe9ac;
$getColor: #bee8de;
$indigo: #536dc8;
$danube: #6191d1;
$white: #fff;
$bermuda: #77D7B9;
$pageColor: #babbbb;

$width: 1500px;
$breakpoints: (
  "sp": "screen and (max-width: 420px)",
  "sm-tab": "screen and (max-width: 768px)",
  "bg-tab": "screen and (max-width: 1200px)",
  "pc": "screen and (min-width: #{$width})",
  "bg-pc": "screen and (min-width: 2200px)",
);

@mixin mq($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin mainFont {
    font-family: "bovine-mvb", sans-serif;
    font-weight: 400;
    font-style: italic;
}

@mixin center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}